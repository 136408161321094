<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3">
            <div class="p-5 bg-white rounded-lg shadow-lg">
                <ul class="list-none">
                    <li
                        v-for="(item, index) in tabs"
                        :key="index"
                        class="py-3 text-center cursor-pointer"
                        :class="
                            item.value[0] === choseTab[0]
                                ? 'bg-red-500 bg-opacity-20 rounded-lg'
                                : ''
                        "
                        @click="changeTab(item.value)"
                    >
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-span-9">
            <SearchFormByUnpaid
                v-if="route.params.status == '0'"
                ref="searchFormRefDom"
                @onReset="resetData"
                @onSearch="filterSearch"
            />
            <SearchForm
                v-else
                ref="searchFormRefDom"
                @onReset="resetData"
                @onSearch="filterSearch"
            />
            <div
                v-if="datas.length > 0"
                v-loading="loading"
                element-loading-text="載入中"
                element-loading-spinner="el-icon-loading"
                element-loading-customClass="items-center flex justify-center text-red-600"
                class="mt-5 overflow-x-auto bg-white rounded-lg shadow-lg"
            >
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <TableHead
                            ref="tableHeadRefDom"
                            :labels="tableLabels"
                            @changeSort="changeSort"
                        />
                        <!-- 未繳回訂單區塊 -->
                        <tbody
                            v-if="
                                route.params.status == '0' && !$isEmpty(datas)
                            "
                        >
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td>
                                    {{ item.order_id }}
                                </td>
                                <td v-if="item.provider">
                                    {{ $subString(item.provider.name, 10) }}
                                </td>
                                <td>
                                    {{ item.cut | formatCurrency }}
                                </td>
                                <td>
                                    {{ item.cut_due_time | formatDateTime }}
                                </td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                        <!-- 未繳回繳款單區塊 -->
                        <tbody
                            v-if="
                                route.params.status == 'unPaymentSlip' &&
                                !$isEmpty(datas)
                            "
                        >
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td v-if="item.slip_id">
                                    {{ item.slip_id }}
                                </td>
                                <td v-if="item.user">
                                    {{ $subString(item.user.name, 10) }}
                                </td>
                                <td v-if="item.latest_payment">
                                    {{
                                        item.latest_payment.amount
                                            | formatCurrency
                                    }}
                                </td>
                                <td v-if="item.latest_payment === null">
                                    未開立藍新繳費單
                                </td>
                                <td
                                    v-if="
                                        item.latest_payment &&
                                        item.latest_payment.details
                                    "
                                >
                                    {{
                                        item.latest_payment.details.NewebPay
                                            .expire | formatDateTime
                                    }}
                                </td>
                                <td v-if="item.latest_payment === null">
                                    未開立藍新繳費單
                                </td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                        <!-- 已通過審核款項區塊 -->
                        <tbody
                            v-if="
                                route.params.status == '9' && !$isEmpty(datas)
                            "
                        >
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td>
                                    {{ item.slip_id }}
                                </td>
                                <td v-if="item.user">
                                    {{ $subString(item.user.name, 10) }}
                                </td>
                                <td>
                                    {{ item.receivable | formatCurrency }}
                                </td>
                                <td v-if="item.latest_payment">
                                    {{
                                        orderPaymentTypes[
                                            item.latest_payment.type
                                        ]
                                    }}
                                </td>
                                <td v-if="item.latest_payment">
                                    {{
                                        item.latest_payment.pay_time
                                            | formatDateTime
                                    }}
                                </td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="route.params.status == '-1'">
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td>
                                    {{ item.slip_id }}
                                </td>
                                <td v-if="item.user">
                                    {{ $subString(item.user.name, 10) }}
                                </td>
                                <td>
                                    {{ item.receivable | formatCurrency }}
                                </td>
                                <td v-if="item.latest_payment">
                                    {{
                                        orderPaymentTypes[
                                            item.latest_payment.type
                                        ]
                                    }}
                                </td>
                                <td v-if="item.latest_payment">
                                    {{
                                        item.latest_payment.pay_time
                                            | formatDateTime
                                    }}
                                </td>
                                <td
                                    v-permission="['update']"
                                    class="cursor-pointer"
                                    @click="goToEdit(item.id)"
                                >
                                    <i class="far fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="flex justify-center mt-3 mb-3">
                    <MyPagination
                        :pagination-data="paginationData"
                        :current-page="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange"
                    />
                </div>
            </div>
            <div
                v-else
                class="mt-5 overflow-x-auto justify-center text-gray-400 bg-white rounded-lg shadow-lg min-h-[200px] flex items-center"
            >
                尚無資料
            </div>
        </div>
    </div>
</template>



<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 搜尋未繳回訂單表單
import SearchFormByUnpaid from "./SearchFormByUnpaid.vue";
// 搜尋其他繳回狀態訂單
import SearchForm from "./SearchForm.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 判斷是 empty 方法
import { isEmpty } from "@/service/anyService";
import store from "@/store";
import orderConfig from "@/config/orderConfig";
const __sfc_main = {
  name: "CashOrderStatus"
};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  const errorCallback = err => store.dispatch("apiStore/errorCallback", {
    err
  });
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // table head dom
  const tableHeadRefDom = ref(null);
  // 預設搜尋條件
  const defaultFilterOpts = {
    statuses: [parseInt(route.params.status)]
  };

  // 現金單繳款方式
  const orderPaymentTypes = ref(orderConfig.cashOrderPaymentTypes);
  // 搜尋條件值
  const filterOpts = ref(defaultFilterOpts);
  // 整理搜尋條件
  const filterData = computed(() => {
    const filter = filterOpts.value;
    for (const key in filterOpts.value) {
      // 判斷 key 是否有值
      if (!isEmpty(filterOpts.value[key])) {
        filter[key] = filterOpts.value[key];
      }
    }
    // 判斷是 未繳回繳款單 時 觸發
    if (route.params.status === "unPaymentSlip") {
      filter.statuses = [0, 2, 3];
    } else {
      filter.statuses = [parseInt(route.params.status)];
    }
    const result = {
      ...filterOpts.value,
      page: currentPage.value,
      limit: paginationData.value.limit
    };
    return result;
  });
  // table head 標題
  const tableLabels = ref([{
    label: "訂單編號"
  }, {
    label: "服務商暱稱"
  }, {
    label: "應繳回金額"
  }, {
    label: "截止時間"
  }, {
    label: "查看"
  }]);
  // 搜尋表單 dom
  const loading = ref(false);
  // 搜尋表單 dom
  const searchFormRefDom = ref(null);
  // 分頁資料
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  // 預設頁面
  const currentPage = ref(1);
  // 列表
  const datas = ref([]);
  // 選擇 tab

  const choseTab = ref(route.params.status === "unPaymentSlip" ? ["unPaymentSlip"] : [parseInt(route.params.status)]);
  console.log(choseTab.value);
  // 審核狀態列表
  const tabs = ref([{
    label: "未繳回訂單",
    value: [0]
  }, {
    label: "未繳回繳款單",
    value: ["unPaymentSlip"]
  }, {
    label: "已通過審核款項",
    value: [9]
  }]);
  /**
   * 選擇 tab 事件
   * @param { type Number(數字) } val 驗證狀態
   */
  async function changeTab(val) {
    if (choseTab.value !== val) {
      router.push({
        name: "cash_order",
        params: {
          status: val[0]
        }
      });
    }
    filterOpts.value = defaultFilterOpts;
    await getList();
    choseTab.value = val;
    searchFormRefDom.value.resetData();
    setTableHeadColumn(val[0]);
  }

  /**
   * 更換每頁筆數
   * @param { type Number(數字) } val 每頁筆數
   */
  async function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    await getList();
  }

  /**
   * 分頁切換
   * @param { type Number(數字) } val 下一頁數字
   */
  async function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    await getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    loading.value = true;
    let orders = {};
    try {
      // 當狀態是 0 代表為 服務商未繳回款項訂單列表
      if (route.params.status == 0) {
        // 取得 現金單 服務商未繳回款項訂單列表
        orders = await getUnpaidCashOrderList(filterData.value);
        datas.value = orders.data;
      } else {
        // 取得 現金單 (待審核 | 已審核 | 已通過) 款項訂單列表
        orders = await getPaymentBySlipOrderList(filterData.value);
        datas.value = orders.data;
        loading.value = false;
      }
      currentPage.value = orders.current_page;
      paginationData.value = {
        limit: orders.per_page,
        total: orders.total
      };
      loading.value = false;
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得訂單列表失敗"
      });
      loading.value = false;
    }
  }
  /**
   * 取得 現金單 服務商未繳回款項訂單列表
   * @param { type Object(物件) } filterData 過濾參數
   */
  async function getUnpaidCashOrderList(filterData) {
    try {
      const {
        data
      } = await proxy.$api.GetUnpaidCashOrderListApi(filterData);
      return data;
    } catch (err) {
      errorCallback(err);
    }
  }
  /**
   * 取得 現金單 (待審核 | 已審核 | 已通過) 款項訂單列表
   * @param { type Object(物件) } filterData 過濾參數
   */
  async function getPaymentBySlipOrderList(filterData) {
    try {
      const {
        data
      } = await proxy.$api.GetPaymentBySlipOrderListApi(filterData);
      return data;
    } catch (err) {
      errorCallback(err);
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goToEdit(orderId) {
    // 判斷點擊未繳回訂單時觸發
    if (route.params.status == "0") {
      router.push({
        name: "order_detail",
        params: {
          userID: 0,
          pre: "OrderManageList",
          tab: "provider_update",
          orderID: orderId
        }
      });
    } else {
      router.push({
        name: "cash_order_id",
        params: {
          status: route.params.status,
          id: orderId
        }
      });
    }
  }

  // 點擊重置按鈕事件
  async function resetData() {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    if (tableHeadRefDom.value !== null) {
      tableHeadRefDom.value.reset();
    }
    paginationData.value = {
      limit: 10,
      total: 0
    };
    await getList();
  }
  // 點擊搜尋按鈕事件
  async function filterSearch(val) {
    currentPage.value = 1;
    filterOpts.value = val;
    await getList();
  }
  // 更換排序
  const changeSort = async val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    await getList();
  };
  // 設定 tableHeadColumn
  function setTableHeadColumn(val) {
    if (val == "0") {
      tableLabels.value = [{
        label: "訂單編號"
      }, {
        label: "服務商暱稱"
      }, {
        label: "應繳回金額"
      }, {
        label: "截止時間"
      }, {
        label: "查看"
      }];
    } else if (val === "unPaymentSlip") {
      tableLabels.value = [{
        label: "繳回編號"
      }, {
        label: "服務商暱稱"
      }, {
        label: "應繳回金額"
      }, {
        label: "繳回截止時間"
      }, {
        label: "查看"
      }];
    } else if (val == "9" || val == "-1") {
      tableLabels.value = [{
        label: "繳回編號"
      }, {
        label: "服務商暱稱"
      }, {
        label: "繳回金額"
      }, {
        label: "繳回方式"
      }, {
        label: "繳回時間"
      }, {
        label: "查看"
      }];
    } else {
      tableLabels.value = [{
        label: "訂單編號"
      }, {
        label: "服務商暱稱"
      }, {
        label: "繳回金額"
      }, {
        label: "末四碼"
      }, {
        label: "繳回時間"
      }, {
        label: "查看"
      }];
    }
  }
  watch(() => route.params.status, async (newV, oldV) => {
    if (newV !== undefined) {
      setTableHeadColumn(newV);
      if (route.params.status === "unPaymentSlip") {
        choseTab.value = ["unPaymentSlip"];
      } else {
        choseTab.value = [parseInt(newV)];
      }
      await getList();
    }
  });
  onMounted(async () => {
    await getList();
    setTableHeadColumn(route.params.status);
  });
  onActivated(async () => {
    // await getList();
    setTableHeadColumn(route.params.status);
  });
  return {
    route,
    tableHeadRefDom,
    orderPaymentTypes,
    tableLabels,
    loading,
    searchFormRefDom,
    paginationData,
    currentPage,
    datas,
    choseTab,
    tabs,
    changeTab,
    pageSizeChange,
    currentPageChange,
    goToEdit,
    resetData,
    filterSearch,
    changeSort
  };
};
__sfc_main.components = Object.assign({
  SearchFormByUnpaid,
  SearchForm,
  TableHead,
  MyPagination
}, __sfc_main.components);
export default __sfc_main;
</script>
