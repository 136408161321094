<template>
    <div class="px-5 pt-3 pb-5 bg-white rounded-lg shadow-lg">
        <el-form ref="form" :rules="rules" :model="form">
            <div class="flex items-end">
                <div class="flex-1">
                    <div class="grid w-full grid-cols-12 gap-4">
                        <div class="col-span-6">
                            <div class="flex items-end">
                                <el-form-item
                                    class="mb-0 flex-grow-0 w-[90px]"
                                    prop="countryCode"
                                >
                                    <label class="text-gray-400">
                                        服務商手機
                                        <el-select
                                            v-model="form.countryCode"
                                            clearable
                                            class="w-full"
                                        >
                                            <el-option
                                                v-for="item in ['886']"
                                                :key="item"
                                                :value="item"
                                                :label="item"
                                            ></el-option>
                                        </el-select>
                                    </label>
                                </el-form-item>
                                <el-form-item
                                    class="flex-1 flex-grow mb-0"
                                    prop="mobile"
                                >
                                    <el-input
                                        v-model.number="form.mobile"
                                        clearable
                                        class="w-full"
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div
                            v-if="$route.params.status !== 'unPaymentSlip'"
                            class="col-span-6"
                        >
                            <el-form-item
                                class="flex-1 flex-grow mb-0"
                                prop="account"
                            >
                                <label class="text-gray-400"> 末四碼 </label>
                                <el-input
                                    v-model="form.account"
                                    clearable
                                    class="w-full"
                                    maxlength="4"
                                >
                                </el-input>
                            </el-form-item>
                        </div>
                        <div
                            :class="
                                $route.params.status === 'unPaymentSlip'
                                    ? 'col-span-6'
                                    : 'col-span-12'
                            "
                        >
                            <el-form-item
                                class="flex-1 flex-grow mb-0"
                                style="margin-top: -1px"
                            >
                                <label class="text-gray-400">
                                    繳回日期 範圍
                                </label>
                                <el-date-picker
                                    v-model="dateInputs"
                                    type="daterange"
                                    value-format="yyyy-MM-dd"
                                    range-separator="至"
                                    start-placeholder="開始日期"
                                    end-placeholder="结束日期"
                                />
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0 ml-4 min-w-[150px]">
                    <btn
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink mr-1"
                        @onClick="onSubmit('form')"
                    >
                        搜尋
                    </btn>
                    <btn
                        color="border border-black"
                        size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                        customClass="flex-shrink"
                        @onClick="resetData"
                    >
                        重置
                    </btn>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
// 日期格式套件
import moment from "moment";
// 判斷是否有選擇值
import { checkHaveSelectData } from "@/service/anyService";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
export default {
    components: {
        Btn,
    },
    data() {
        return {
            form: {
                countryCode: "886",
            },
            rules: {
                mobile: [
                    {
                        validator: this.checkPhoneFirstStringZero,
                        trigger: "blur",
                    },
                ],
                countryCode: [{ message: "國碼為必填", trigger: "change" }],
                account: [
                    {
                        pattern: /^[0-9]*$/,
                        message: `末四碼必須為整數`,
                        trigger: "blur",
                    },
                ],
            },
            // 搜尋條件
            filterData: {},
            // 時間範圍
            dateInputs: [],
        };
    },
    methods: {
        // 判斷手機號碼中 是否第一個字為 0
        checkPhoneFirstStringZero(rule, value, callback) {
            // 判斷沒有輸入手機號碼時不執行檢查
            if (this.$isEmpty(value)) {
                callback();
                return;
            }
            if (value.length > 0 && value[0] == 0) {
                this.$set(this.form, "phone", value.substring(1));
                callback();
            } else {
                callback();
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.filterSearch();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 搜尋條件
        async filterSearch() {
            // 判斷是否有輸入手機號碼
            if (!this.$isEmpty(this.form.mobile)) {
                // 組合手機號碼
                this.form.phone = this.form.countryCode + this.form.mobile;
            } else {
                // 須清空組合後得手機號碼 否則還是一樣會列入搜尋條件
                this.form.phone = null;
            }
            // 判斷是否有值 有值就帶入搜尋條件
            checkHaveSelectData(
                [this.form.phone, this.form.account],
                ["phone", "account"],
                this.filterData
            );
            checkHaveSelectData(
                [this.filterData.pay_time_start, this.filterData.pay_time_end],
                ["pay_time_start", "pay_time_end"],
                this.filterData
            );
            console.log(this.filterData);
            this.$emit("onSearch", this.filterData);
        },
        // 清空表單資料
        resetData() {
            this.form = {
                countryCode: "886",
            };
            this.dateInputs = [];
            this.$refs.form.resetFields();
            this.$emit("onReset", {});
        },
    },
    watch: {
        // 監聽日期 選擇參數
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterData.pay_time_start = "";
                this.filterData.pay_time_end = "";
                return;
            }
            this.filterData.pay_time_start = val[0];
            this.filterData.pay_time_end = val[1];
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    @apply w-full;
}
</style>

<style lang="scss" scoped>
.phone-select {
    width: 150px;
    margin-right: 5px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
